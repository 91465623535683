//import { Button, Modal } from "antd";
//import React, { useState } from "react";
//import Advertisement from '../assets/Advertisement.png';

//const AdvertiseMentModal = ( { onNext, resetAdvertisement, nudge } ) => {
//    const [isModalOpen, setIsModalOpen] = useState( true );

//    const handleOk = () => {
//        setIsModalOpen( false );
//        resetAdvertisement();
//        onNext();
//    };

//    return (
//        <>
//            <Modal
//                title=""
//                open={ isModalOpen }
//                onOk={ handleOk }
//                onCancel={ null }
//                okText="OK"
//                okButtonProps={ { className: "bg-blue-500" } }
//                cancelButtonProps={ { style: { display: "none" } } }
//                centered
//                width="500px"
//                closable={false}
//            >
//                <div className="text-center">
//                    <h1 className="text-4xl font-semibold mb-4">{ nudge.name }</h1>
//                    <p className="text-gray-600 text-lg mb-4">{ nudge.text }</p>
//                    <img
//                        src={ Advertisement }
//                        alt="Nudge Image"
//                        className="mx-auto h-120"
//                    />
//                </div>
//            </Modal>
//        </>
//    );
//};

//export default AdvertiseMentModal;

import React, { useState } from "react";
import { Image, Spin } from 'antd';
import Advertisement from '../assets/Advertisement.png';

const AdvertiseMentModal = ( { onNext, resetAdvertisement, nudge } ) => {
    const handleOk = () => {
        resetAdvertisement();
        onNext();
    };

    const getIdFromDriveLink = ( driveLink ) => {
        if ( driveLink ) {
            const idMatch = driveLink.match( /\/d\/([^/]+)\// );
            if ( idMatch && idMatch[1] ) {
                return idMatch[1];
            }
        }
        return null;
    };

    const imageId = getIdFromDriveLink( nudge?.image_link );

    const imageSrc =
        nudge.image_link === null
            ? Advertisement : imageId
                ? `https://drive.google.com/uc?export=view&id=${ imageId }`
                : '';

    console.log( imageSrc )
    const isImageLoaded = imageSrc !== '';

    return (
        <>
            <div className="mt-2">
                <div className="text-center">
                    <h1 className="text-4xl font-semibold mb-4">{ nudge.name }</h1>
                    <p className="text-gray-600 text-lg mb-4">{ nudge.text }</p>
                    { isImageLoaded ? (
                        <Image
                            src={ imageSrc }
                            alt="Nudge Image"
                            className="mx-auto h-120"
                            preview={ false }
                        />
                    ) : (
                        <Spin size="large" />
                    ) }
                </div>
            </div>
            <div className="flex flex-row gap-2 justify-end mt-4">
                <button
                    className="px-4 py-2 rounded-md text-white bg-blue-500"
                    onClick={ handleOk }
                    disabled={ !isImageLoaded } // Disable the button until the image is loaded
                >
                    Next
                </button>
            </div>
        </>
    );
};

export default AdvertiseMentModal;
