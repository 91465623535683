import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';
import 'react-phone-number-input/style.css'
import "../../styles.css";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const PhoneNumberInputStep = ( { question, answer, onAnswerChange, onNext, onBack } ) => {

    const [isValid, setIsValid] = useState( false );
    const [phoneNumber, setPhoneNumber] = useState( "" );
    const [maxLength, setMaxLength] = useState( 10 );

    useEffect( () => {
        console.log( "Current maxLength: ", maxLength );
    }, [maxLength] );

    useEffect( () => {
        console.log( answer );
        if ( answer ) {
            setPhoneNumber( answer );
            if ( isValidPhoneNumber( answer ) ) {
                setIsValid( true );
            } else (
                setIsValid( false )
            )
        }
    }, [question, answer] );

    const handleInputChange = ( e ) => {
        onAnswerChange( e );
        e && isValidPhoneNumber( e ) ? setIsValid( true ) : setIsValid( false );
        setPhoneNumber( e );
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );

        if ( question.text.includes( "Please provide coordinator's contact number" ) ) {
            localStorage.setItem( 'contact_person_phone', e );
        }
    };

    const { client_name } = useParams();

    const isPagination = dynamicData[client_name].is_pagination;
    const is_question_navigation = dynamicData[client_name].is_question_navigation &&
    window.location.href.includes( 'test' );

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <QuestionAndDescription question={ question } />
                    <div className="mt-2">
                        <PhoneInput
                            international
                            className="sm:w-full lg:w-1/2 rounded-md"
                            defaultCountry="IN"
                            value={ answer }
                            onChange={ ( e ) => handleInputChange( e ) }
                            //onBlur={ handlePhoneInputBlur }
                            placeholder={ question?.placeholder_text }
                            countryCallingCodeEditable={ false }
                        />
                    </div>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    is_question_navigation ?
                        <>
                            <SubmitButton onNext={ onNext } />
                        </>
                        :
                        <div className="flex flex-row gap-2 justify-end mt-4">
                            {
                                question?.question_optional === "optional" && (
                                    <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                                        Skip
                                    </button>
                                )
                            }
                            <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                                Back
                            </button>
                            <button
                                className={ `px-4 py-2 rounded-md text-white ${ isValid ? "bg-blue-500" : "bg-blue-300" }` }
                                onClick={ isValid ? onNext : null }
                                style={ isValid ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                            >
                                Next
                            </button>
                        </div>
            }
        </>
    );
};

export default PhoneNumberInputStep;
