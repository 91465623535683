import React from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, Link } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { useSelector } from 'react-redux';
import useFetchPdfReport from '../../api/useFetchPdfReport';
import useCreateUserAssessmentResponse from '../../api/CreateUserAccessment';
import useFetchData from '../../api/useFetchData';
import { notification, Spin } from 'antd';


const ThankyouTTM = ( { studentName } ) => {
    return (
        <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
            <h1 className="text-2xl font-bold mb-4">Congratulations! 🎉</h1>
            <p className="mb-6">Your mentorship call has been successfully scheduled.</p>
            <h1 className="text-2xl font-bold mb-4">We Can't Wait to Meet You! 🤝</h1>
            <p className="mb-8">Your journey to accelerated career growth starts now. See you soon!</p>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Join Our Community 🌐</h2>
                <a href="https://chat.whatsapp.com/GpVUYtpQVII6T2S4wm5ank" className="block bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                    Whatsapp community
                </a>
                <p>Don't miss out on the action. Join our vibrant community for tips, networking, and more!</p>
            </div>

            <div className="mb-6">
                <h2 className="text-xl font-semibold mb-2">Explore Curated Resources 📚</h2>
                <a href="https://strideahead123-my.sharepoint.com/:b:/g/personal/team_strideahead_in/EayG8qlNzUlV70qQZc54MSQBiGbhTyeQWR5zOqtLYwF8rg?e=8prBdz" className="block bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                    Curated resources
                </a>
                <p>Level up your skills with our expertly curated resource library.</p>
            </div>
        </div>
    );
};

export default ThankyouTTM