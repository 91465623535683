import { configureStore } from '@reduxjs/toolkit';
import { studentReducer, parentReducer, clientReducer, schoolRegistrationReducer, studentRegistrationReducer, currentQuestionReducer, consentFormReducer, updateAnswerReducer, formSubmittedReducer, loadingScreenReducer } from '../redux/student/studentSlice';

const store = configureStore( {
    reducer: {
        student: studentReducer,
        parent: parentReducer,
        client: clientReducer,
        schoolRegistration: schoolRegistrationReducer,
        studentRegistration: studentRegistrationReducer,
        currentQuestion: currentQuestionReducer,
        consentForm: consentFormReducer,
        answersSlice: updateAnswerReducer,
        formSubmitted: formSubmittedReducer,
        loadingScreen: loadingScreenReducer,
    },
} );

export default store;
