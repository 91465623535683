import React, { useState } from 'react';
import { Button, Select } from 'antd';
import { questionsWithinIndia, questionsAbroad } from '../questionsConfig';
import { TagInput, MultiStepChoice } from './helper';

const { Option } = Select;

const CustomSelect = ( { options, selected, onSelect } ) => (
    <Select value={ selected } onChange={ onSelect } className="w-full">
        { options.map( ( option, index ) => (
            <Option key={ index } value={ option }>
                { option }
            </Option>
        ) ) }
    </Select>
);

const QuestionStepValidation = ( { question, selected, onSelect, onNext, onBack } ) => {
    const [followUpStep, setFollowUpStep] = useState( 0 );
    const [nextButtonEnabled, setNextButtonEnabled] = useState( false );
    const [showquestion, setshowquestion] = useState( false );
    const [followUpSelected, setFollowUpSelected] = useState( [] );
    const [stateSelections, setStateSelections] = useState( [] );

    const followUpQuestions =
        selected === 'Within India' ? questionsWithinIndia : questionsAbroad;

    const handleFollowUpSelect = ( option ) => {
        setFollowUpSelected( [...followUpSelected, option] );
        setNextButtonEnabled( true ); // Enable the "Next" button when an option is selected.
    };

    const handleMainSelect = ( option ) => {
        onSelect( option );
        setFollowUpStep( 0 );
        setshowquestion( true );
    };

    // Handle state selections from TagInput
    const handleStateSelection = ( states ) => {
        setStateSelections( states );
        setNextButtonEnabled( true ); // Enable next button when states are selected
    };

    // Updated the 'Next' button click handler to handle follow-up questions
    const handleNextClick = () => {
        if ( followUpStep < 1 ) {
            setFollowUpStep( followUpStep + 1 );
            setNextButtonEnabled( false ); // Disable next button for next follow-up question
        } else {
            onNext( selected ); // Handle final submission
        }
    };

    const handleMultiStepChoiceSelect = ( selection ) => {
        if ( selection.length > 0 || selection.some( item => item.trim() !== '' ) ) {
            setNextButtonEnabled( true );
        } else {
            setNextButtonEnabled( false );
        }
    };

    const renderFollowUpQuestion = () => {
        if ( followUpStep !== 0 ) {
            return (
                <CustomSelect
                    options={ followUpQuestions[followUpStep].options }
                    onSelect={ handleFollowUpSelect }
                />
            );
        }

        if ( selected === "Within India" ) {
            return (
                <TagInput
                    options={ followUpQuestions[followUpStep].options }
                    onSelectStates={ handleStateSelection }
                />
            );
        }

        return (
            <MultiStepChoice
                options={ questionsAbroad[0].options }
                onSelect={ handleMultiStepChoiceSelect }
            />
        );
    };

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <h1 className="font-poppins text-2xl font-medium bg-white mb-4">
                        { question.question }
                    </h1>
                    <CustomSelect options={ question.options } selected={ selected } onSelect={ handleMainSelect } />
                    <div className="flex mt-4">
                        { followUpStep > -1 && showquestion && (
                            <div>
                                <h2 className="font-poppins text-2xl font-medium bg-white mb-4">
                                    { followUpQuestions[followUpStep].question }
                                </h2>
                                { showquestion && renderFollowUpQuestion() }
                            </div>
                        ) }
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-2 justify-end mt-4">
                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack } >
                    Back
                </button>
                <button className="px-4 py-2 rounded-md text-white" onClick={ handleNextClick }>
                    Next
                </button>
            </div>
        </>
    );
};

export default QuestionStepValidation;
