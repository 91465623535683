import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, CREATE_USER_QUESTION_RESPONSE
} from '../utils/constants/constants';


const useCreateUserQuestionResponse = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const API_URL = `${ BASE_URL }${ CREATE_USER_QUESTION_RESPONSE }`;

    const createUserQuestionResponse = async ( questionID, userAssessmentResponseID, optionIDs = [], textResponse ) => {
        setIsLoading( true );
        const safeOptionIDs = Array.isArray( optionIDs ) ? optionIDs : [optionIDs];
        const optionsArray = safeOptionIDs.map( id => ( { option_id: id } ) );
        const requestBody = {
            question_id: questionID,
            text_response: textResponse,
            time_taken: 1,
            user_assessment_response_id: userAssessmentResponseID,
            options: optionsArray,
        };

        try {
            const res = await axios.post( API_URL, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return [createUserQuestionResponse, response, error, isLoading];
};

export default useCreateUserQuestionResponse;
