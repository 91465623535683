import React, { useState, useEffect } from "react";
import { Select } from "antd";
import { indianStates } from "../../helpers/data";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";

const { Option } = Select;

const IndianStateSelectionQuestion = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
  const [indianState, setIndianState] = useState( null );
  const [isOptionSelected, setIsOptionSelected] = useState( false );

  const handleIndianStateChange = ( value ) => {
    setIndianState( value );
    onAnswerChange( value );
    setIsOptionSelected( true );
  };

  useEffect( () => {
    if ( answer && indianStates.includes( answer ) ) {
      setIndianState( answer );
      setIsOptionSelected( true );
    }
  }, [question, answer] );

  const { client_name } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <div className="space-y-2 mt-4">
            <Select
              showSearch={ true }
              placeholder="Enter your State"
              className="w-1/2 sm:w-full lg:w-1/2"
              value={ indianState }
              onChange={ handleIndianStateChange }
            >
              { indianStates.map( ( state, index ) => (
                <Option key={ index } value={ state }>
                  { state }
                </Option>
              ) ) }
            </Select>
          </div>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          <div className="flex flex-row gap-2 justify-end mt-4">
            {
              question?.question_optional === "optional" && (
                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                  Skip
                </button>
              )
            }
            <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
              Back
            </button>
            <button
              className={ `px-4 py-2 rounded-md text-white ${ isOptionSelected ? "bg-blue-500" : "bg-blue-300" }` }
              onClick={ isOptionSelected ? onNext : null }
              style={ isOptionSelected ? { cursor: "pointer" } : { cursor: "not-allowed" } }
            >
              Next
            </button>
          </div>
      }
    </>
  );
};

export default IndianStateSelectionQuestion;
