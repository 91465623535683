import React, { useState, useEffect, useRef } from "react";
import "../css/style.css";
import "../css/bootstrap.min.css";
import Logo from "../img/logo.png"
import { Image, Spin, Tooltip, Modal } from 'antd';
import { dynamicData } from "../helpers/data";
import { useParams } from "react-router-dom";
import { setIsStudent, setIsParent, setIsSchoolRegistration, setIsStudentRegistration, setQuestionNumber, setIsFormSubmitted, setIsLoadingScreen } from '../redux/student/studentSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
    ControlOutlined,
    FlagFilled, InsertRowAboveOutlined
} from '@ant-design/icons';
import SegmentInstructions from "./InstructionPage/SegmentInstructions";
import updateUserResponse from "../api/useUpdateUserResponse";


const ConfirmationModal = ( { isOpen, onOk, onCancel } ) => {
    return (
        <>
            <Modal title="Are you sure you want to submit?" open={ isOpen } onOk={ onOk } onCancel={ onCancel } okButtonProps={ { style: { backgroundColor: 'green', color: 'white' } } } okText="Submit" centered>
                <p>Once submitted, answers cannot be altered.</p>
            </Modal>
        </>
    );
};


const LeftSideBar = ( { data } ) => {
    const isStudent = useSelector( state => state.student.isStudent );
    const isParent = useSelector( state => state.parent.isParent );
    const isSchoolRegistration = useSelector( state => state.schoolRegistration.isSchoolRegistration )
    const isStudentRegistration = useSelector( state => state.studentRegistration.isStudentRegistration )
    const questionNumber = useSelector( state => state.currentQuestion.questionNumber );
    const textAnswers = useSelector( ( state ) => state.answersSlice.textAnswers );
    const [selectedQuestionIndex, setSelectedQuestionIndex] = useState( null );
    const [activeQuestionIndex, setActiveQuestionIndex] = useState( null );
    const [isModalOpen, setIsModalOpen] = useState( false );
    const [isInstructionsModalOpen, setIsInstructionsModalOpen] = useState( false );

    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
    const isLoadingScreen = useSelector( state => state.loadingScreen.isLoadingScreen );

    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' );

    const { response: updateUserResponseData, isLoading: updateUserResponseLoading, error: updateUserResponseError, updateResponse } = updateUserResponse();

    const showModal = () => {
        setIsModalOpen( true );
    };

    const handleOk = () => {
        setIsModalOpen( false );
    };

    const handleCancel = () => {
        setIsModalOpen( false );
    };

    const showInstructionsModal = () => {
        setIsInstructionsModalOpen( true );
    }

    const handleCloseInstructionsModal = () => {
        setIsInstructionsModalOpen( false );
    };

    const { client_name, id, grade } = useParams();

    let heading = dynamicData[client_name].default_left_section.heading;
    let subHeading = dynamicData[client_name].default_left_section.sub_heading;
    let image = dynamicData[client_name].default_left_section.image_link;
    let copyrightText = dynamicData[client_name].default_left_section.copyright_text;
    let clientLogo = dynamicData[client_name].default_left_section.logo_link;

    const student_route = dynamicData[client_name].student_route;
    const parent_route = dynamicData[client_name].parent_route;
    const school_registration_route = dynamicData[client_name].school_registration_route;
    const student_registration_route = dynamicData[client_name].student_registration_route;

    const numberOfQuestions = data?.questions?.length || 0;

    const isQuestionNavigation =
        dynamicData[client_name].is_question_navigation &&
        window.location.href.includes( 'test' ) && !isFormSubmitted && !isLoadingScreen;

    const dispatch = useDispatch();
    const [flaggedQuestions, setFlaggedQuestions] = useState( [] );

    useEffect( () => {
        if ( id === student_route ) {
            dispatch( setIsStudent( true ) );
            dispatch( setIsParent( false ) );
        } else if ( id === parent_route ) {
            dispatch( setIsParent( true ) );
            dispatch( setIsStudent( false ) );
        } else if ( id === school_registration_route ) {
            dispatch( setIsSchoolRegistration( true ) );
            dispatch( setIsStudentRegistration( false ) );
        } else if ( id === student_registration_route ) {
            dispatch( setIsStudentRegistration( true ) );
            dispatch( setIsSchoolRegistration( false ) );
        }
    }, [id] )

    if ( isStudent ) {
        heading = dynamicData[client_name]?.student_content?.left_section?.heading || heading;
        subHeading = dynamicData[client_name]?.student_content?.left_section?.sub_heading || subHeading;
        image = dynamicData[client_name]?.student_content?.left_section?.image_link || image;
        copyrightText = dynamicData[client_name]?.student_content?.left_section?.copyright_text || copyrightText;
        clientLogo = dynamicData[client_name]?.student_content?.left_section?.logo_link || clientLogo
    } else if ( isParent ) {
        heading = dynamicData[client_name]?.parent_content?.left_section?.heading || heading;
        subHeading = dynamicData[client_name]?.parent_content?.left_section?.sub_heading || subHeading;
        image = dynamicData[client_name]?.parent_content?.left_section?.image_link || image;
        copyrightText = dynamicData[client_name]?.parent_content?.left_section?.copyright_text || copyrightText
        clientLogo = dynamicData[client_name]?.parent_content?.left_section?.logo_link || clientLogo
    }

    if ( isSchoolRegistration ) {
        heading = dynamicData[client_name]?.school_registration_content?.left_section?.heading || heading;
        subHeading = dynamicData[client_name]?.school_registration_content?.left_section?.sub_heading || subHeading;
        image = dynamicData[client_name]?.school_registration_content?.left_section?.image_link || image;
        copyrightText = dynamicData[client_name]?.school_registration_content?.left_section?.copyright_text || copyrightText;
        clientLogo = dynamicData[client_name]?.school_registration_content?.left_section?.logo_link || clientLogo

    } else if ( isStudentRegistration ) {
        heading = dynamicData[client_name]?.student_registration_content?.left_section?.heading || heading;
        subHeading = dynamicData[client_name]?.student_registration_content?.left_section?.sub_heading || subHeading;
        image = dynamicData[client_name]?.student_registration_content?.left_section?.image_link || image
        copyrightText = dynamicData[client_name]?.student_registration_content?.left_section?.copyright_text || copyrightText
        clientLogo = dynamicData[client_name]?.student_registration_content?.left_section?.logo_link || clientLogo
    } else if ( isLoadingScreen || isFormSubmitted ) {
        heading = dynamicData[client_name]?.loading_page?.left_section?.heading || heading;
        subHeading = dynamicData[client_name]?.loading_page?.left_section?.sub_heading || subHeading;
        image = dynamicData[client_name]?.loading_page?.left_section?.image_link || image
        copyrightText = dynamicData[client_name]?.loading_page?.left_section?.copyright_text || copyrightText
        clientLogo = dynamicData[client_name]?.loading_page?.left_section?.logo_link || clientLogo
    }

    const handleButtonClick = ( index ) => {
        console.log( "condition", questionNumber === index )
        console.log( "index", index );
        setSelectedQuestionIndex( index );
        dispatch( setQuestionNumber( index ) );
        localStorage.setItem( 'questionNumber', index );
    };

    const handleFinishAttempt = () => {
        updateResponse( { id: userAssessmentResponseID, is_completed: 3 } )
        dispatch( setIsLoadingScreen( true ) );
        localStorage.setItem( 'isLoadingScreen', true )
        setIsModalOpen( false );
    }
    console.log( "questionNumber2", questionNumber );


    const buttonRefs = useRef( [] );


    //const handleFinishAttempt = () => {
    //    dispatch(setIsFormSubmitted(true));
    //    setIsModalOpen(false);

    //    // Add an entry to the history stack
    //    window.history.pushState(null, null, window.location.pathname);

    //    // Add an event listener for the popstate event
    //    window.addEventListener('popstate', function (event) {
    //        // Handle the back action here, e.g., by warning the user or redirecting them
    //        alert('You have already submitted the form. Please do not go back.');
    //        window.history.pushState(null, null, window.location.pathname);
    //    }, { once: true }); // 'once: true' ensures the event is only handled once

    //useEffect( () => {
    //    const handleMouseDown = ( event ) => {
    //        // Check if the clicked element is not one of your question buttons
    //        if ( !buttonRefs.current.some( ref => ref && ref.contains( event.target ) ) ) {
    //            event.preventDefault();
    //        }
    //    };

    //    // Add the event listener on mount
    //    window.addEventListener( 'mousedown', handleMouseDown );

    //    // Clean up the event listener on unmount
    //    return () => {
    //        window.removeEventListener( 'mousedown', handleMouseDown );
    //    };
    //}, [] );

    useEffect( () => {
        setActiveQuestionIndex( questionNumber )
        //if ( questionNumber >= 0 && questionNumber < numberOfQuestions ) {
        //    buttonRefs.current[questionNumber]?.focus();
        //    buttonRefs.current[activeQuestionIndex]?.focus();
        //}
    }, [questionNumber, numberOfQuestions, activeQuestionIndex] );

    return (
        <>
            {
                isQuestionNavigation ?
                    <>
                        <div className="bg-[#CCCCCC] p-0 col-xl-4 col-lg-4">
                            <div className="flex flex-col justify-center items-center h-full min-h-full p-16 pb-9 text-white text-center relative md:h-auto md:p-[95px,30px,35px,30px] lg:p-[95px,15px,35px,15px]">
                                <div className="w-full flex justify-center mb-4">
                                    <button
                                        className="rounded-md text-white p-3 mb-2 bg-blue-500"
                                        onClick={ showInstructionsModal }
                                    >
                                        Instructions
                                    </button>
                                    <SegmentInstructions isOpen={ isInstructionsModalOpen } onOk={ handleCloseInstructionsModal } />
                                </div>
                                <div className="w-full flex justify-between mb-4 gap-1">
                                    <Tooltip placement="top" title="You have the option to mark questions for a review later. These questions will still be included in the scoring.">
                                        <button
                                            className="bg-white text-[#3F52FD] rounded px-4 py-2 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-white"
                                            onClick={ () => {
                                                setFlaggedQuestions( [...flaggedQuestions, questionNumber] );
                                            } }
                                        >
                                            Mark For Review
                                        </button>
                                    </Tooltip>
                                    <Tooltip placement="top" title="This removes the 'Mark for Review' indication from a question.">
                                        <button
                                            className="bg-white text-[#3F52FD] rounded px-4 py-2 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-white"
                                            onClick={ () => {
                                                setFlaggedQuestions(
                                                    flaggedQuestions.filter( ( q ) => q !== questionNumber )
                                                );
                                            } }
                                        >
                                            Clear Review
                                        </button>
                                    </Tooltip>
                                    <button
                                        className="bg-white text-[#3F52FD] rounded px-4 py-2 font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-white"
                                        onClick={ showModal }>
                                        Finish attempt
                                    </button>
                                    <ConfirmationModal isOpen={ isModalOpen } onOk={ handleFinishAttempt } onCancel={ handleCancel } />
                                </div>
                                <div className="flex flex-wrap gap-2">
                                    { Array.from( { length: numberOfQuestions }, ( _, index ) => (
                                        <button
                                            key={ index }
                                            ref={ ( el ) => ( buttonRefs.current[index] = el ) }
                                            className={ `w-10 h-10 text-[#3F52FD] rounded-full font-bold focus:outline-none
                                                ${ activeQuestionIndex === index ? 'ring-2 ring-offset-2 ring-offset-[#3F52FD] ring-red' : '' }
                                                ${ questionNumber === index ? 'focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#3F52FD] focus:ring-red' : '' }
                                                ${ textAnswers[index] ? 'bg-green-400 text-[#3F52FD]' : 'bg-white text-[#3F52FD]' }
                                                ${ flaggedQuestions.includes( index ) ? 'relative' : '' }` }
                                            onClick={ () => {
                                                handleButtonClick( index );
                                                setSelectedQuestionIndex( index );
                                            } }
                                        >
                                            { index + 1 }
                                            { flaggedQuestions.includes( index ) && (
                                                <FlagFilled className="absolute top-0 right-0 text-red-500" />
                                            ) }
                                        </button>
                                    ) ) }
                                </div>
                            </div>
                        </div>
                    </>
                    :
                    <>
                        <div className="bg-[#3F52FD] p-0 col-xl-4 col-lg-4">
                            <div className="flex justify-center items-center h-full min-h-full p-16 pb-9 text-white text-center relative md:h-auto md:p-[95px,30px,35px,30px] lg:p-[95px,15px,35px,15px]">
                                <a id="logo">
                                    <img src={ clientLogo } alt="logo" width="120" height="120" />
                                </a>
                                <div className="hidden md:block">
                                    <Image src={ image } alt="" preview={ false } />
                                    <h2 className="text-white mt-2 font-bold">{ heading }</h2>
                                    <p className="text-white text-base mt-4">{ subHeading }</p>
                                </div>
                                <div className="hidden md:block copy">{ copyrightText }</div>
                            </div>
                        </div>
                    </>
            }
        </>
    )
};

export default LeftSideBar;
