import { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
  BASE_URL, CREATE_USER_ASSESSMENT_RESPONSE
} from '../utils/constants/constants';

const useCreateUserAssessmentResponse = ( userId, formId, iscompleted ) => {
  const [response, setResponse] = useState( null );
  const [error, setError] = useState( null );
  const [isLoading, setIsLoading] = useState( false );
  const isFirstMount = useRef( true ); // Initialize a useRef

  const API_URL = `${ BASE_URL }${ CREATE_USER_ASSESSMENT_RESPONSE }`;

  useEffect( () => {
    if ( isFirstMount.current ) { // Check if it's the first mount
      if ( formId ) { // Ensure formId is available
        //console.log( 'FORM ID', formId );
        setIsLoading( true );

        const requestBody = {
          user_id: userId,
          form_id: formId,
          is_completed: Number( iscompleted ),
        };

        axios
          .post( API_URL, requestBody )
          .then( ( res ) => {
            setResponse( res.data );
            setIsLoading( false );
          } )
          .catch( ( err ) => {
            setError( err );
            setIsLoading( false );
          } );

        isFirstMount.current = false;
      }
    }
  }, [formId] );

  return [response, error, isLoading];
};

export default useCreateUserAssessmentResponse;
