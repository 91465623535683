import React, { useState } from "react";
import axios from 'axios';
import { BASE_URL, VERIFY_COUPON_CODE } from '../utils/constants/constants';

const useVerifyCouponCode = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );

    const verifyCouponCode = async ( id, email ) => {
        console.log( "id", id )
        setIsLoading( true );
        setError( null );
        try {
            const response = await axios.get( `${ BASE_URL }${ VERIFY_COUPON_CODE }{student_email}/${ id }?user_email=${ email }` );
            console.log(response);
            setResponse( response );
            setIsLoading( false );
        } catch ( err ) {
            setError( err );
            setIsLoading( false );
        }
    };

    return { response, isLoading, error, verifyCouponCode };
};

export default useVerifyCouponCode;
