import React, { useState, useEffect } from "react";

const SubmitButton = ( { onNext, onClearAll } ) => {
    return (
        <>
            <div className="flex flex-row gap-2 justify-end mt-4 mb-5">
                <button
                    className="px-4 py-2 rounded-md text-white bg-blue-500"
                    onClick={ onNext }
                >
                    Submit
                </button>
                <button
                    className="px-4 py-2 rounded-md text-white bg-red-500" 
                    onClick={ onClearAll }
                >
                    Clear All
                </button>
            </div>
        </>
    )
}

export default SubmitButton;