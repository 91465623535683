import React, { useState, useEffect } from "react";
import { Radio, Select, Input } from "antd";
import { indianStates } from "../../helpers/data";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";
import SubmitButton from "../SubmitButton/SubmitButton";

const { Option } = Select;

const CustomSelections = ( { onIndianStateChange, onOtherStateChange, indianState, otherState, isSelectDisabled, isInputDisabled } ) => {
  return (
    <div className="flex flex-col items-start cursor-pointer bg-white p-2 rounded-md">
      <Select
        showSearch={ true }
        placeholder="Enter your State"
        className={ `w-1/2 sm:w-full lg:w-1/2 ${ isSelectDisabled ? 'bg-gray-200' : '' }` }
        value={ indianState }
        onChange={ onIndianStateChange }
        disabled={ isSelectDisabled }
      >
        { indianStates.map( ( state, index ) => (
          <Option key={ index } value={ state }>
            { state }
          </Option>
        ) ) }
      </Select>
      <input
        className={ `w-1/2 sm:w-full lg:w-1/2 rounded p-3 border-2 focus:border-blue-500 mt-3 ${ isInputDisabled ? 'bg-gray-200 cursor-not-allowed' : 'bg-white' }` }
        style={ { resize: "none", textAlign: "left" } }
        value={ otherState }
        onChange={ onOtherStateChange }
        placeholder="Enter your state (for eg. Texas)"
        disabled={ isInputDisabled }
      />
    </div>
  );
};


const StateSelectionQuestion = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
  const [isOptionSelected, setIsOptionSelected] = useState( false );
  const [indianState, setIndianState] = useState( null );
  const [otherState, setOtherState] = useState( "" );
  const [selectedRadio, setSelectedRadio] = useState( null );

  const isSelectDisabled = selectedRadio !== "withinIndia";
  const isInputDisabled = selectedRadio !== "outsideIndia";

  const handleIndianStateChange = ( value ) => {
    setIndianState( value );
    onAnswerChange( value );
    setIsOptionSelected( true );
  };

  const handleOtherStateChange = ( e ) => {
    const value = e.target.value;
    setOtherState( value );
    onAnswerChange( value );
    setIsOptionSelected( true );
  };

  useEffect( () => {
    if ( answer ) {
      if ( indianStates.includes( answer ) ) {
        setSelectedRadio( "withinIndia" );
        setIndianState( answer );
        setIsOptionSelected( true );
      } else {
        setSelectedRadio( "outsideIndia" );
        setOtherState( answer );
        setIsOptionSelected( true );
      }
    }
  }, [question, answer] )


  const handleRadioChange = ( e ) => {
    setSelectedRadio( e.target.value );
    setIsOptionSelected( false );
  };

  const { client_name } = useParams();

  const isPagination = dynamicData[client_name].is_pagination;
  const is_question_navigation = dynamicData[client_name].is_question_navigation &&
  window.location.href.includes( 'test' );

  return (
    <>
      <div className="mt-2">
        <div className="justify-content">
          <QuestionAndDescription question={ question } />
          <Radio.Group onChange={ handleRadioChange } value={ selectedRadio }>
            <Radio.Button value="withinIndia">Within India</Radio.Button>
            <Radio.Button value="outsideIndia">Outside India</Radio.Button>
          </Radio.Group>
          <div className="space-y-2 mt-4">
            <CustomSelections
              onIndianStateChange={ handleIndianStateChange }
              onOtherStateChange={ handleOtherStateChange }
              indianState={ indianState }
              otherState={ otherState }
              isSelectDisabled={ isSelectDisabled }
              isInputDisabled={ isInputDisabled }
            />
          </div>
        </div>
      </div>
      {
        isPagination ?
          <></>
          :
          is_question_navigation ?
            <>
              <SubmitButton onNext={ onNext } />
            </>
            :
            <div className="flex flex-row gap-2 justify-end mt-4">
              {
                question?.question_optional === "optional" && (
                  <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                    Skip
                  </button>
                )
              }
              <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                Back
              </button>
              <button
                className={ `px-4 py-2 rounded-md text-white ${ isOptionSelected ? "bg-blue-500" : "bg-blue-300" }` }
                onClick={ isOptionSelected ? onNext : null }
                style={ isOptionSelected ? { cursor: "pointer" } : { cursor: "not-allowed" } }
              >
                Next
              </button>
            </div>
      }
    </>
  );
};

export default StateSelectionQuestion;
