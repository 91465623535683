import React, { useState } from 'react';
import axios from 'axios';
import {
    FETCH_PDF_REPORT
} from '../utils/constants/constants';

const useFetchPdfReport = () => {
    const [data, setData] = useState( null );
    const [isError, setIsError] = useState( false );
    const [isLoading, setIsLoading] = useState( false ); // Initialize loading state as false

    // Ensure fetchData returns a Promise.
    const fetchData = async ( requestBody ) => {
        setIsLoading( true );
        return new Promise( async ( resolve, reject ) => {
            try {
                const response = await axios.post( FETCH_PDF_REPORT, requestBody );
                resolve( response.data );
            } catch ( error ) {
                setIsError( true );
                reject( error );
            } finally {
                setIsLoading( false );
            }
        } );
    };

    return { data, isLoading, isError, fetchData };
};

export default useFetchPdfReport;