import React, { useState, useEffect, useRef } from "react";
import { Modal } from "antd";

const CountdownModal = ( { isOpen, onOk, hours, minutes, seconds } ) => {
    return (
        <Modal open={ isOpen } onOk={ onOk } okButtonProps={ { style: { backgroundColor: 'blue', color: 'white', width: '80px' } } } centered width={ 500 } cancelButtonProps={ { style: { display: 'none' } } } closeIcon={ false }>
            <p className="text-xl font-bold text-gray-700 mb-4">The test will start in</p>
            <p className="text-xl font-medium text-gray-500 mb-0"> <span className="text-2xl font-semibold text-green-500">{ hours }</span> hours <span className="text-2xl font-semibold text-green-500">{ minutes }</span> minutes <span className="text-2xl font-semibold text-green-500">{ seconds }</span> seconds.</p>
        </Modal>
    );
};


export default CountdownModal;