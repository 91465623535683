import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { setIsLoadingScreen } from '../../redux/student/studentSlice';
import { notification } from "antd";
import { useParams } from "react-router-dom";

const TestTimer = ( { testTimeInSeconds } ) => {
  const dispatch = useDispatch();
  const { client_name, grade } = useParams();
  const storageId = 'countdown-timer';
  const endTime = useState( () => {
    const savedEndTime = localStorage.getItem( storageId );
    return savedEndTime ? parseInt( savedEndTime, 10 ) : Date.now() + testTimeInSeconds * 1000;
  } )[0];

  const calculateTimeLeft = () => {
    const currentTime = Date.now();
    const timeLeft = Math.max( endTime - currentTime, 0 ) / 1000;
    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState( calculateTimeLeft );

  const [notificationShown, setNotificationShown] = useState( false );

  useEffect( () => {
    localStorage.setItem( storageId, endTime.toString() );

    const interval = setInterval( () => {
      const newTimeLeft = calculateTimeLeft();
      setTimeLeft( newTimeLeft );

      if ( newTimeLeft === 0 ) {
        clearInterval( interval );
        dispatch( setIsLoadingScreen( true ) );
      }

      if ( newTimeLeft < 600 && !notificationShown ) {
        notification.warning( {
          message: 'Hurry Up!',
          description: 'Your test is about to end! To ensure your results are taken into account, prioritize completing the Psychometric Questions, i.e. Segment -1 [Ques 1-78]  first.',
          duration: null
        } );
        setNotificationShown( true );
      }
    }, 1000 );

    return () => clearInterval( interval );
  }, [dispatch, endTime, notificationShown] );

  const timerColor = timeLeft < 600 ? 'text-red-600' : 'text-green-600'; // Less than 10 minutes

  const formatTime = () => {
    const minutes = Math.floor( timeLeft / 60 );
    const seconds = Math.floor( timeLeft % 60 ); // Round down to the nearest whole number
    return `${ minutes }:${ seconds < 10 ? '0' : '' }${ seconds }`;
  };

  return (
    <div className="flex justify-center items-center">
      <div className="p-6 max-w-sm mx-auto bg-white rounded-xl shadow-md flex items-center space-x-4">
        <div className="flex flex-col items-center justify-center">
          <div className="text-xl font-medium text-black">Grade: { grade }</div>
          <div className="text-xl font-medium text-black">Time Remaining</div>
          <p className={ `text-4xl font-bold ${ timerColor } mb-0` }>{ formatTime() }</p>
        </div>
      </div>
    </div>
  );
};

export default TestTimer;
