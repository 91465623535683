import React, { useEffect } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { PROPERTY_ID, CLIENT_ID } from '../utils/constants/constants';
import { Button } from 'antd';

const EventAnalyticsData = () => {
    const propertyId = PROPERTY_ID;
    const startDate = '2023-06-01';
    const endDate = '2023-10-15';

    const googleLogin = useGoogleLogin( {
        clientId: CLIENT_ID,
        responseType: 'token',
        onSuccess: async ( tokenResponse ) => {
            const accessToken = tokenResponse?.access_token;
            if ( accessToken ) {
                fetchData( "ya29.a0AfB_byDXsp_FMddzEHqVt_cKripLocyJApqt9abmv7tmYgRrk0MON0MilwZ9F9LOuuGESkHsFEDz5KYXAElhcWTLfeov9dSHP7b_V5EoKiiVhtTFEy0lD0Bg8gir4pl17VVFBrsROw2LxbYOu3FEtF_7X8FnshAauAxBHgaCgYKASASARASFQHsvYlssPGiGGwjW2TosAFJb5rHcA0173" )
            }
        },
        onFailure: ( error ) => {
            console.error( error );
        },
    } );

    const fetchData = async ( accessToken ) => {
        try {
            const requestBody = {
                "dateRanges": [{ "startDate": "7daysAgo", "endDate": "yesterday" }],
                "dimensions": [{ "name": "date" }],
                "metrics": [{ "name": "eventCount" }],
              }

            const headers = {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${ accessToken }`,
            };

            const apiResponse = await axios.post(
                `https://analyticsdata.googleapis.com/v1beta/properties/${ propertyId }:runReport`,
                requestBody,
                { headers }
            );

            const responseData = apiResponse.data;

            console.log( responseData );
        } catch ( error ) {
            console.error( error );
        }
    };

    useEffect( () => {
        if ( googleLogin.tokenResponse?.access_token ) {
            fetchData( googleLogin.tokenResponse.access_token );
        }
    }, [googleLogin.tokenResponse] );

    return (
        <div className="App">
            <h1>Event Analytics Report</h1>
            <Button onClick={ googleLogin }>Check Event Analytics</Button>
        </div>
    );
};

export default EventAnalyticsData;

