import AWS from 'aws-sdk';
import {
    AWS_ACCESS_KEY_ID,
    AWS_SECRET_ACCESS_KEY,
    S3_BUCKET_NAME
} from "../utils/constants/constants";

const uploadFileToS3 = async ( file ) => {

    const params = {
        Bucket: S3_BUCKET_NAME,
        Key: file.name,
        Body: file,
    };

    AWS.config.update( {
        accessKeyId: AWS_ACCESS_KEY_ID,
        secretAccessKey: AWS_SECRET_ACCESS_KEY,
    } );

    const s3 = new AWS.S3( {
        params: {
            Bucket: S3_BUCKET_NAME,
        },
        region: process.env.REACT_APP_AWS_REGION,
    } );

    return new Promise( ( resolve, reject ) => {
        s3.upload( params, ( err, data ) => {
            if ( err ) {
                console.error( 'Error uploading to S3:', err );
                reject( err );
            } else {
                console.log( 'File uploaded successfully to S3:', data.Location );
                resolve( data.Location );
            }
        } );
    } );
};

export default uploadFileToS3;
