export const TRACKING_ID = process.env.REACT_APP_TRACKING_ID_GOOGLE_ANALYTICS;
export const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;
export const PROPERTY_ID = process.env.REACT_APP_PROPERTY_ID;
export const AWS_ACCESS_KEY_ID = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
export const AWS_SECRET_ACCESS_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
export const AWS_REGION = process.env.REACT_APP_AWS_REGION;
export const S3_BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const CREATE_USER_ASSESSMENT_RESPONSE = process.env.REACT_APP_CREATE_USER_ASSESSMENT_RESPONSE_API;
export const CREATE_USER_QUESTION_RESPONSE = process.env.REACT_APP_CREATE_USER_QUESTION_RESPONSE_API;
export const FORM = process.env.REACT_APP_FORM_API;
export const TERMS_AND_CONDITIONS = process.env.REACT_APP_TERMS_AND_CONDITIONS;
export const CREATE_SCHOOL = process.env.REACT_APP_CREATE_SCHOOL;
export const CREATE_STUDENT = process.env.REACT_APP_CREATE_STUDENTS;
export const READ_SCHOOL = process.env.REACT_APP_READ_SCHOOL;
export const GENERATE_OTP_EMAIL = process.env.REACT_APP_VERIFY_GENERATE_OTP_EMAIL;
export const AUTHENTICATE_STUDENT = process.env.REACT_APP_AUTHENTICATE_STUDENTS;
export const ASKIITIAN_TERMS_AND_CONDITIONS = process.env.REACT_APP_ASKIITIAN_TERMS_AND_CONDITIONS;
export const FETCH_PDF_REPORT = process.env.REACT_APP_FETCH_PDF_REPORT;
export const FETCH_DATA = process.env.REACT_APP_FETCH_DATA_URL;
export const UPDATE_USER_RESPONSE = process.env.REACT_APP_UPDATE_USER_RESPONSE;
export const VERIFY_COUPON_CODE = process.env.REACT_APP_VERIFY_COUPON_CODE;