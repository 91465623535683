import React from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, Link } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { useSelector } from 'react-redux';
import useFetchPdfReport from '../../api/useFetchPdfReport';
import useCreateUserAssessmentResponse from '../../api/CreateUserAccessment';
import useFetchData from '../../api/useFetchData';
import { notification, Spin } from 'antd';

const ThankyouNnit = ( { studentName } ) => {
    const isStudent = useSelector( state => state.student.isStudent );

    const { client_name } = useParams();

    let pageContent = isStudent ? "student_content" : "parent_content";
    let thankYouPage = dynamicData[client_name]?.[pageContent]?.thank_you_page || {};

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let multiple_sections = thankYouPage?.multiple_sections || [];

    return (
        <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto">
            <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
            <p className="mb-6">{ sub_heading_one }</p>
            <p className="mb-8">{ sub_heading_two }</p>

            { multiple_sections.map( ( section, index ) => (
                <div className="mb-6" key={ index }>
                    <h2 className="text-xl font-semibold mb-2">{ section.heading }</h2>
                    <Link to={ section.cta_links } className="block bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                        { section.cta_text }
                    </Link>
                    <p>{ section.sub_heading }</p>
                </div>
            ) ) }
        </div>
    );
};

export default ThankyouNnit