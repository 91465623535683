import React, { useState, useEffect } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import LeftSideBar from '../LeftSideBar';
import '../../css/style.css';
import "../../css/bootstrap.min.css";
import CollegeSurvey from '../../assets/college_form.png';
import WorkingProfessional from '../../assets/professional_survey.png';
import ParentForm from '../../assets/parent_form.png';
import StudentForm from '../../assets/student_form.png';
import SchoolForm from '../../assets/school_form.png'
import StudentRegistration from '../../assets/student_registration_form.png'
import { useDispatch, useSelector } from 'react-redux';
import { setIsClient, setIsStudent, setIsParent, setIsSchoolRegistration, setIsStudentRegistration, setIsConsentForm } from '../../redux/student/studentSlice';
import { dynamicData } from '../../helpers/data';
import { useParams } from 'react-router-dom';
import TestTermsAndConditions from '../TermsAndConditions/TermsAndConditions';
import { TERMS_AND_CONDITIONS } from '../../utils/constants/constants';

const FormSelector = () => {
    const dispatch = useDispatch();
    const [selected, setSelected] = useState( "" );
    const [showTerms, setShowTerms] = useState( true );

    const { client_name } = useParams();

    useEffect( () => {
        if ( dynamicData[client_name] ) {
            document.title = dynamicData[client_name].document_title;
            document.querySelector( 'meta[name="description"]' ).setAttribute( "content", dynamicData[client_name].document_description );
        }
    }, [client_name, dynamicData] );


    const is_college_form = dynamicData[client_name].is_college_form;
    const is_parent_form = dynamicData[client_name].is_parent_form;
    const is_student_form = dynamicData[client_name].is_student_form;
    const is_working_professional_form = dynamicData[client_name].is_working_professional_form;

    const isClientRoute = dynamicData[client_name].is_client_route;
    const student_route = dynamicData[client_name].student_route;
    const parent_route = dynamicData[client_name].parent_route;
    const is_school_registration_form = dynamicData[client_name].is_school_registration_form;
    const is_student_registration_form = dynamicData[client_name].is_student_registration_form;

    const school_registration_route = dynamicData[client_name].school_registration_route;
    const student_registration_route = dynamicData[client_name].student_registration_route;

    const isConsentForm = useSelector( state => state.consentForm.isConsentForm )

    const selectCard = ( card ) => {
        setSelected( card );
        let productCode = "";
        if ( card === "college" ) {
            productCode = "COLLEGE_SURVEY";
        } else if ( card === "working" ) {
            productCode = "PROFESSIONAL_SURVEY";
        }

        if ( card === "student" ) {
            dispatch( setIsStudent( true ) );
            dispatch( setIsParent( false ) );
        } else if ( card === "parent" ) {
            dispatch( setIsParent( true ) );
            dispatch( setIsStudent( false ) );
        } else if ( card === "student_registration" ) {
            dispatch( setIsStudentRegistration( true ) );
            dispatch( setIsSchoolRegistration( false ) );
        } else if ( card === "school_registration" ) {
            dispatch( setIsSchoolRegistration( true ) );
            dispatch( setIsStudentRegistration( false ) );
        }
        localStorage.setItem( 'product_code', productCode );
    };

    useEffect( () => {
        if ( isClientRoute ) {
            dispatch( setIsClient( true ) );
        }
    }, [isClientRoute, dispatch] );

    const handleProceedToQuiz = () => {
        dispatch( setIsConsentForm( false ) );
    };

    return (
        <div className="container-fluid">
            { isConsentForm && (
                <TestTermsAndConditions onSubmit={ handleProceedToQuiz } link={ TERMS_AND_CONDITIONS } />
            ) }
            <div className="row row-height">
                <LeftSideBar />
                <div className="col-xl-8 col-lg-8 content-right" id="start">
                    <>
                        <div id="wizard_container">
                            <div className="flex flex-col items-center mt-20 space-y-8">
                                <h1 className="text-4xl font-medium">I'm a</h1>
                                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 gap-x-28 gap-y-8">
                                    { is_college_form && (
                                        <div
                                            onClick={ () => selectCard( "college" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "college" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ CollegeSurvey } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">College Student </h3>
                                        </div>
                                    ) }
                                    { is_student_form && (
                                        <div
                                            onClick={ () => selectCard( "student" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "student" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ StudentForm } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Student</h3>
                                        </div>
                                    ) }
                                    { is_student_registration_form && (
                                        <div
                                            onClick={ () => selectCard( "student_registration" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "student_registration" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ StudentRegistration } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Student Registration</h3>
                                        </div>
                                    ) }
                                    { is_school_registration_form && (
                                        <div
                                            onClick={ () => selectCard( "school_registration" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "school_registration" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ SchoolForm } alt="Image 1" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">School Registration</h3>
                                        </div>
                                    ) }
                                    { is_parent_form && (
                                        <div
                                            onClick={ () => selectCard( "parent" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "parent" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ ParentForm } alt="Image 2" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Parent </h3>
                                        </div>
                                    ) }
                                    { is_working_professional_form && (
                                        <div
                                            onClick={ () => selectCard( "working" ) }
                                            className={ `flex flex-col items-center rounded-md shadow-md p-4 transition-transform transform hover:translate-y-[-5px] ${ selected === "working" ? "border-4 border-green-500" : ""
                                                }` }
                                        >
                                            <img src={ WorkingProfessional } alt="Image 2" className="w-32 h-32 rounded-full" />
                                            <h3 className="text-sm font-medium">Working Professional</h3>
                                        </div>
                                    ) }
                                </div>
                                <div className="mt-8">
                                    <Link
                                        to={ `/${ client_name }/${ selected === "college" ? "69" : selected === "working" ? "70" : selected === "student" ? student_route : selected === "parent" ? parent_route : selected === "student_registration" ? student_registration_route : selected === "school_registration" ? school_registration_route : "" }` }
                                    >
                                        <button className={ `px-4 py-2 rounded-md border-2 text-white bg-blue-500` }>
                                            Start Now
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </>
                </div>
            </div>
        </div>
    );
};

export default FormSelector;
