import React, { useState, useEffect } from "react";
import { Button, Input, notification } from "antd";
import {
    EditTwoTone,
    CheckCircleOutlined,
} from '@ant-design/icons';
import useVerifyOTP from "../../api/useVerifyOtp";
import useRequestOTP from "../../api/useRequestOtp";
import { setIsFormSubmitted } from "../../redux/student/studentSlice";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from 'react-router-dom';
import PhoneInput, { isValidPhoneNumber, formatPhoneNumber, getCountryCallingCode } from 'react-phone-number-input';

const MobileVerification = ( {
    onAnswerChange, phoneNumber, onNext, onBack, isTimeCompleted
} ) => {
    //const [isMobileValid, setIsMobileValid] = useState( false );
    const [isValid, setIsValid] = useState( false );
    const [isOTPBoxVisible, setIsOTPBoxVisible] = useState( false );
    const [isEditable, setIsEditable] = useState( false );
    const [mobileNumber, setMobileNumber] = useState( "" );
    const [otpValues, setOtpValues] = useState( ["", "", "", ""] );
    const [isCheckIcon, setIsCheckIcon] = useState( false );

    const [isSendOTPClickable, setIsSendOTPClickable] = useState( true );
    const [isVerifyOTPClickable, setIsVerifyOTPClickable] = useState( false );
    const isFormSubmitted = useSelector( state => state.formSubmitted.isFormSubmitted );
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { client_name, grade } = useParams();

    useEffect( () => {
        setMobileNumber( phoneNumber );
        //setIsMobileValid( /^\d{10}$/.test( phoneNumber ) );
    }, [phoneNumber] );

    const { phoneNumberData, isRequestingOTP, errorOTP, requestOTP } = useRequestOTP();
    const { data, isLoading, error, verifyOTP } = useVerifyOTP();

    useEffect( () => {
        if ( !isLoading ) {
            if ( data && !error ) {
                notification.success( {
                    message: 'OTP Verified',
                    description: 'Your OTP has been successfully verified.'
                } );

                dispatch( setIsFormSubmitted( true ) );

                if ( client_name === 'icat' && grade ) {
                    dispatch( setIsFormSubmitted( true ) );
                    navigate( `/${ client_name }/test/${ grade }/thank_you` );

                    // Clear all items in localStorage except 'testID'
                    Object.keys( localStorage ).forEach( ( key ) => {
                        if ( key !== 'userAssessmentResponseID' && key !== 'student_name' && key !== 'student_roll_number' ) {
                            localStorage.removeItem( key );
                        }
                    } );
                    localStorage.setItem( 'isFormSubmitted', true )
                }

                localStorage.setItem( 'isFormSubmitted', true )

            } else if ( error ) {
                notification.error( {
                    message: 'OTP Verification Failed',
                    description: 'The OTP you entered is incorrect. Please try again.'
                } );
            }
        }
    }, [data, isLoading, error, client_name, grade, dispatch, navigate] );


    const handleEditNumber = () => {
        setIsEditable( true );
        setIsCheckIcon( true );
        setIsSendOTPClickable( false );
    };

    const handleCheckNumber = () => {
        if ( isValid ) {
            setIsEditable( false );
            setIsCheckIcon( false );
            setIsSendOTPClickable( true );
        }
    };

    const handleSendOTP = async () => {
        onNext();
        setIsOTPBoxVisible( true );
        setIsSendOTPClickable( false );
        setIsVerifyOTPClickable( true );
        localStorage.setItem( 'phone_number', mobileNumber );
        requestOTP( mobileNumber );
    };

    const handleMobileNumberChange = ( value ) => {
        value && isValidPhoneNumber( value ) ? setIsValid( true ) : setIsValid( false );
        onAnswerChange( value );
        setMobileNumber( value );
        //setIsMobileValid( /^\d{10}$/.test( value ) );
    };

    const handleOTPChange = ( e, index ) => {
        const otp = e.target.value;

        if ( otp.length === 0 || /^[0-9]$/.test( otp ) ) {
            const newOtpValues = [...otpValues];
            newOtpValues[index] = otp;
            setOtpValues( newOtpValues );

            if ( otp.length === 0 && index > 0 ) {
                document.getElementById( `otp-input-${ index - 1 }` ).focus();
            } else if ( otp.length === 1 && index < 3 ) {
                document.getElementById( `otp-input-${ index + 1 }` ).focus();
            }
        }
    };

    const handleVerificationSubmit = async () => {
        const otp = otpValues.join( "" );
        // Make an API call to verify OTP
        verifyOTP( mobileNumber, otp );
    };

    return (
        <>
            <div className={ `${ isFormSubmitted ? 'mt-[30%]' : '' }` }>
                <div className="mt-2">
                    <h1 className="font-poppins text-2xl font-medium bg-white mb-2 select-none">
                        Verify your Phone Number
                    </h1>
                    <h1 className="font-poppins text-sm font-normal text-gray-700 mb-4 select-none">
                        You are just one step away! Verify your OTP to access your results.
                    </h1>
                    <>
                        {/*<input
                            disabled={ !isEditable }
                            className={ `w-1/2 rounded ${ isEditable ? " bg-white" : "bg-gray-300" } p-3 border-2 focus:border-blue-500` }
                            style={ { resize: "none" } }
                            value={ mobileNumber }
                            onChange={ ( e ) => {
                                setMobileNumber( e.target.value );
                                handleMobileNumberChange( e.target.value );
                            } }
                        />*/}
                        <PhoneInput
                            international
                            //disabled={ !isEditable }
                            className={ `w-1/2 rounded ${ isEditable ? "cursor-pointer" : "cursor-none" } p-3 border-2 focus:border-blue-500` }
                            defaultCountry="IN"
                            value={ mobileNumber }
                            onChange={ ( e ) => {
                                setMobileNumber( e );
                                handleMobileNumberChange( e );
                            } }
                            //onBlur={ handlePhoneInputBlur }
                            countryCallingCodeEditable={ false }
                        />
                        {/*{
                            isCheckIcon ? (
                                <CheckCircleOutlined
                                    onClick={ handleCheckNumber }
                                    style={ { fontSize: '25px', cursor: isValid ? 'pointer' : 'not-allowed' } }
                                    className={ `ml-4 ${ isValid ? "#008000" : "#FF0000" }` }
                                />
                            ) : (
                                <EditTwoTone
                                    onClick={ handleEditNumber }
                                    style={ { fontSize: '25px' } }
                                    className="ml-4"
                                    twoToneColor="#2196F3"
                                />
                            )
                        }*/}
                    </>
                </div>
                { isOTPBoxVisible && (
                    <>
                        <div className="flex text-center mt-2">
                            { otpValues.map( ( value, index ) => (
                                <Input
                                    key={ index }
                                    id={ `otp-input-${ index }` }
                                    className="mr-4 w-10 h-15"
                                    value={ value }
                                    maxLength={ 1 }
                                    onChange={ ( e ) => handleOTPChange( e, index ) }
                                />
                            ) ) }
                        </div>
                        <p className="font-poppins text-sm hover:text-red-500 font-medium mt-2">
                            Please enter the OTP sent to your mobile number
                        </p>
                    </>
                ) }
                <div className="flex flex-row gap-2 justify-end mt-4">
                    {
                        !isTimeCompleted ?
                            <>
                                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                                    Back
                                </button>
                            </>
                            :
                            <></>
                    }
                    <>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isValid && isSendOTPClickable ? "bg-blue-500" : "bg-blue-300" }` }
                            onClick={ isValid && isSendOTPClickable ? handleSendOTP : null }
                            style={ isValid && isSendOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Send OTP
                        </button>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ isVerifyOTPClickable ? "bg-green-500" : "bg-green-300" }` }
                            onClick={ isVerifyOTPClickable ? handleVerificationSubmit : null }
                            style={ isVerifyOTPClickable ? { cursor: 'pointer' } : { cursor: 'not-allowed' } }
                        >
                            Verify
                        </button>
                    </>
                </div>
            </div>
        </>
    );
};

export default MobileVerification;
