import React, { useState } from "react";
import axios from 'axios';
import {
    BASE_URL, UPDATE_USER_RESPONSE
} from '../utils/constants/constants';

const useUpdateUserResponse = () => {
    const [response, setResponse] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const updateResponse = async ({ id, is_completed }) => {
        setIsLoading(true);
        setError(null);
        try {
            const API_URL = `${BASE_URL}${UPDATE_USER_RESPONSE}/?id=${id}`;
            const response = await axios.put(API_URL, { is_completed });
            setResponse(response.data); 
            setIsLoading(false);
        } catch (err) {
            setError(err); 
            setIsLoading(false);
        }
    };

    return { response, isLoading, error, updateResponse };
};

export default useUpdateUserResponse;
