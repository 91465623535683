import React, { useEffect } from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import MultiStepForm from '../../MultiStepForm/MainStepForm';
import GoogleAnalyticsApp from "../../api/FetchAnalyticsReport";
import { GoogleOAuthProvider } from "@react-oauth/google";
import InitializeGoogleAnalytics from "../GoogleAnalytics/index";
import { CLIENT_ID } from '../constants/constants';
import '../../styles.css';
import EventAnalyticsData from "../../api/FetchEventData";
import FormSelector from "../../MultiStepForm/FormSelector/FormSelector";
import Review from "../../MultiStepForm/ReviewPage/Review";
import ThankYouPage from "../../MultiStepForm/ThankYouPage/ThankYou";
import TestTermsAndConditions from "../../MultiStepForm/TermsAndConditions/TermsAndConditions";
import SignInPage from "../../MultiStepForm/SignInPage/SignInPage";
import Instructions from "../../MultiStepForm/InstructionPage/Instructions";
import CouponCodeVerification from "../../MultiStepForm/CouponCode/VerificationCouponCode";

const RoutesContainer = () => {
    //useEffect( () => {
    //    console.log( "google analytics initialized" );
    //    InitializeGoogleAnalytics();
    //}, [] );

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={ <TestTermsAndConditions /> } />
                <Route path="/:client_name" element={ <FormSelector /> } />
                <Route path="/:client_name/:id" element={ <MultiStepForm /> } />
                <Route path="/:client_name/test/sign_in_page" element={ <SignInPage /> } />
                <Route path="/:client_name/test/:grade/:id" element={ <MultiStepForm /> } />
                <Route path="/:client_name/thank_you" element={ <ThankYouPage /> } />
                <Route path="/:client_name/test/:grade/thank_you" element={ <ThankYouPage /> } />
                <Route path="/:client_name/test/:grade/instructions" element={ <Instructions /> } />
                <Route path="/:client_name/verification/coupon_code" element={ <CouponCodeVerification /> } />
                <Route path="/google_analytics"
                    element={ <GoogleOAuthProvider
                        clientId={ CLIENT_ID }>
                        <GoogleAnalyticsApp />
                    </GoogleOAuthProvider> } />
                <Route path="/event_analytics"
                    element={ <GoogleOAuthProvider
                        clientId={ CLIENT_ID }>
                        <EventAnalyticsData />
                    </GoogleOAuthProvider> } />
                <Route path="/ani" element={ <Review /> } />
            </Routes>
        </BrowserRouter>
    );
}

export default RoutesContainer;