import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { numberTypeCategory, numberTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";

const FlexibleInputStep = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
    const [isAnswerNumeric, setIsAnswerNumeric] = useState( false );

    const { client_name } = useParams();

    useEffect( () => {
        if ( answer ) {
            setIsAnswerNumeric( true );
        } else {
            setIsAnswerNumeric( false );
        }
    }, [question, answer] );

    const handleInputChange = ( e ) => {
        const inputValue = e.target.value;
        setIsAnswerNumeric( true );
        onAnswerChange( inputValue );
        TrackGoogleAnalyticsEvent( numberTypeCategory, numberTypeAction );

        if ( question.text.includes( "Enter your school's address" ) ) {
            localStorage.setItem( 'userAddress', inputValue );
        }
    };

    const isPagination = dynamicData[client_name].is_pagination;

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <QuestionAndDescription question={ question } />
                    <div>
                        <input
                            type="text"
                            className="w-1/2 sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                            value={ answer }
                            onChange={ ( e ) => handleInputChange( e, question.validation ) }
                            placeholder={ question?.placeholder_text }
                        />
                    </div>
                </div>
            </div>
            { isPagination ? (
                <></>
            ) : (
                <div className="flex flex-row gap-2 justify-end mt-4">
                    { question?.question_optional === "optional" && (
                        <button
                            className="px-4 py-2 rounded-md text-black border-2 bg-white"
                            onClick={ onNext }
                        >
                            Skip
                        </button>
                    ) }
                    <button
                        className="px-4 py-2 rounded-md text-black border-2 bg-white"
                        onClick={ onBack }
                    >
                        Back
                    </button>
                    <button
                        className={ `px-4 py-2 rounded-md text-white ${ isAnswerNumeric ? "bg-blue-500" : "bg-blue-300"
                            }` }
                        onClick={ isAnswerNumeric ? onNext : null }
                        style={
                            isAnswerNumeric
                                ? { cursor: "pointer" }
                                : { cursor: "not-allowed" }
                        }
                    >
                        Next
                    </button>
                </div>
            ) }
        </>
    );
};

export default FlexibleInputStep;

