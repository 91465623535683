import React, { useEffect } from "react";
import RoutesContainer from "./utils/RoutesContainer/RoutesContainer";
import store from "./app/store";
import { Provider } from "react-redux";

function App() {
  return (
    <Provider store={ store }>
      <RoutesContainer />
    </Provider>
  );
}

export default App;
