import { useState } from 'react';
import axios from 'axios';
import {
    BASE_URL, AUTHENTICATE_STUDENT
} from '../utils/constants/constants';

const useAuthenticateStudent = () => {
    const [response, setResponse] = useState( null );
    const [error, setError] = useState( null );
    const [isLoading, setIsLoading] = useState( false );
    const API_URL = `${ BASE_URL }${ AUTHENTICATE_STUDENT }`;

    const AuthenticateStudent = async ( studentInstituteCode, studentDateOfBirth, studentRollNumber ) => {

        setIsLoading( true );
        const requestBody = {
            institute_code: studentInstituteCode,
            dob: studentDateOfBirth,
            roll_number: studentRollNumber,
        };

        try {
            const res = await axios.post( API_URL, requestBody );
            setResponse( res.data );
            setError( null );
        } catch ( err ) {
            setError( err );
        } finally {
            setIsLoading( false );
        }
    };

    return { response, error, isLoading, AuthenticateStudent };
};

export default useAuthenticateStudent;
