import React from 'react';
import LeftSideBar from '../LeftSideBar';
import { useParams, Link } from 'react-router-dom';
import { dynamicData } from '../../helpers/data';
import { useSelector } from 'react-redux';
import useFetchPdfReport from '../../api/useFetchPdfReport';
import useCreateUserAssessmentResponse from '../../api/CreateUserAccessment';
import useFetchData from '../../api/useFetchData';
import { notification, Spin } from 'antd';

export const ThankyouAskIIt = () => {
    const isSchoolRegistration = useSelector( state => state.schoolRegistration.isSchoolRegistration )

    const { client_name } = useParams();

    let pageContent = isSchoolRegistration ? "school_registration_content" : "student_registration_content";
    let thankYouPage = dynamicData[client_name]?.[pageContent]?.thank_you_page || {};

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";
    let sub_heading_four = thankYouPage?.sub_heading_four || "";

    return (
        <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
            <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
            <p className="mb-6">{ sub_heading_one }</p>
            <h1 className="text-2xl font-bold mb-4">{ sub_heading_two }</h1>
            <p className="mb-8">{ sub_heading_three }</p>
        </div>
    );
};

export const ThankyouAskIItTest = () => {

    const { client_name } = useParams();
    const userAssessmentResponseID = localStorage.getItem( 'userAssessmentResponseID' )
    const student_name = localStorage.getItem( 'student_name' )
    const student_roll_number = localStorage.getItem( 'student_roll_number' )
    const { data: formData, isLoading: isFormDataLoading, isError: isFormDataError } = useFetchData( userAssessmentResponseID );
    const { fetchData, isLoading: isPdfDownloading } = useFetchPdfReport();

    let customData = {};

    if ( !isFormDataLoading ) {
        customData = {
            ...formData,
        };
    }

    const handleDownloadReport = async () => {
        const requestBody = {
            data: {
                template_name: "Template9",
                quiz_Type: "icat",
                student_Id: student_roll_number,
                user_name: student_name,
                report_id : userAssessmentResponseID,
                report_metadata: {
                    categories: customData
                }
            }
        };
        console.log( requestBody )
        try {
            const response = await fetchData( requestBody );
            console.log( "API Response:", response );
            if ( response && response.pdf ) {

                const downloadLink = document.createElement( "a" );
                downloadLink.style.display = "none";
                downloadLink.href = response.pdf;
                downloadLink.download = "icat-report.pdf"; // Specify the filename for the download

                // Append the anchor element to the document body
                document.body.appendChild( downloadLink );

                // Trigger a click event on the anchor to start the download
                downloadLink.click();

                // Remove the anchor from the document
                document.body.removeChild( downloadLink );
            } else {
                console.error( "API response does not contain a PDF link." );
                notification.error( {
                    message: 'PDF Download Failed',
                    description: 'Please try Again.',
                } );
            }
        } catch ( error ) {
            console.error( "API Error:", error );
        }
    };

    let thankYouPage = dynamicData[client_name]?.thank_you_page || {};

    let heading = thankYouPage?.heading || "";
    let sub_heading_one = thankYouPage?.sub_heading_one || "";
    let sub_heading_two = thankYouPage?.sub_heading_two || "";
    let sub_heading_three = thankYouPage?.sub_heading_three || "";

    return (
        <>
{/*             {
                isFormDataLoading ?
                    <Spin tip="Loading" size="large" />
                    : */}
                    <div className="p-8 bg-white shadow-lg rounded-lg max-w-xl mx-auto text-center">
                        <h1 className="text-2xl font-bold mb-4">{ heading }</h1>
                        <p className="mb-6">{ sub_heading_one }</p>
                        <h1 className="text-2xl font-bold mb-4">{ sub_heading_two }</h1>
                        <p className="mb-8">{ sub_heading_three }</p>
                        <a href="https://booking.askiitians.com/admin/icat-global-mentorship-session" className="block bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600">
                            Book the Session
                        </a>
{/*                         <button className="block bg-blue-500 text-white text-center rounded py-2 px-4 mb-2 hover:bg-blue-600" onClick={ handleDownloadReport }>
                            Download Report
                        </button> */}
{/*                         { isPdfDownloading && <Spin tip="Loading" size="large" /> } */}
                    </div>

{/*             } */}
        </>
    );
};

