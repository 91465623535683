//DropDown events
export const dropdownCategory ="dropDown";
export const dropdownAction = "Drop_Down_Clicked";

//Number type input events
export const numberTypeCategory ="NumberTypeInput";
export const numberTypeAction = "Number_Typed";

//Text type input events
export const textTypeCategory ="TextTypeInput";
export const textTypeAction = "Text_Typed";

//Multiple choice questions events
export const multipleChoiceCategory ="Multiple_Choice";
export const multipleChoiceAction = "Multiple_Choice_Selected";

//Single choice questions events
export const singleChoiceCategory ="Single_Choice";
export const singleChoiceAction = "Single Choice Selected";

//Upload file Type events
export const uploadTypeCategory ="File_Uploaded";
export const uploadTypeAction = "File_Uploaded";