import React, { useState, useEffect } from "react";
import { TrackGoogleAnalyticsEvent } from "../../utils/GoogleAnalytics/index";
import { textTypeCategory, textTypeAction } from "../../utils/GoogleAnalytics/events/categories";
import '../../styles.css';
import QuestionAndDescription from "../QuestionAndDescription/QuestionandDescription";
import { DatePicker } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { dynamicData } from "../../helpers/data";

const DobInputType = ( { question, answer, onAnswerChange, onNext, onBack } ) => {
    const [isAnswerFilled, setIsAnswerFilled] = useState( false );
    const [firstName, setFirstName] = useState( null );
    const [selectedDate, setSelectedDate] = useState( null );

    useEffect( () => {
        // Convert the ISO date from the answer or local storage to the required format
        if ( answer ) {
            //const formattedDate = moment( answer ).format( dateFormat );
            //setSelectedDate( moment( formattedDate, dateFormat ).toDate() );
            setIsAnswerFilled( true );
            setSelectedDate( answer );
        } else {
            setIsAnswerFilled( false );
            setSelectedDate( null );
        }
    }, [question, answer] );

    const handleAnswerChange = ( date ) => {
        onAnswerChange( date );
        setSelectedDate( date );
        setIsAnswerFilled( true );

        const formattedDate = new Date( date ).toISOString().split( 'T' )[0];
        if ( question.text.includes( "Choose your date of birth from the calendar" ) ) {
            localStorage.setItem( 'studentDateOfBirth', formattedDate );
        }
    };

    const { client_name } = useParams();

    const isPagination = dynamicData[client_name].is_pagination;

    return (
        <>
            <div className="mt-2">
                <div className="justify-content">
                    <QuestionAndDescription question={ question } />
                    <div>
                        <DatePicker
                            className="sm:w-full lg:w-1/2 rounded bg-white p-3 border-2 focus:border-blue-500"
                            onChange={ handleAnswerChange }
                            value={ selectedDate }
                            popupStyle={ {
                                width: '290px', height: '200px',
                            } }
                            showToday={ false }
                        />
                    </div>
                </div>
            </div>
            {
                isPagination ?
                    <></>
                    :
                    <div className="flex flex-row gap-2 justify-end mt-4">
                        {
                            question?.question_optional === "optional" && (
                                <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onNext } >
                                    Skip
                                </button>
                            )
                        }
                        <button className="px-4 py-2 rounded-md text-black border-2 bg-white" onClick={ onBack }>
                            Back
                        </button>
                        <button
                            className={ `px-4 py-2 rounded-md text-white ${ !isAnswerFilled ? "bg-blue-300" : "bg-blue-500" }` }
                            onClick={ !isAnswerFilled ? null : onNext }
                            style={ !isAnswerFilled ? { cursor: "not-allowed" } : { cursor: "pointer" } }
                        >
                            Next
                        </button>
                    </div>
            }
        </>
    );
};

export default DobInputType;
